<template>
  <div class="container grid-sm" style="background-color: white">
    <div class="columns">
      <div class="column col-xs-12" style="padding: 0">
        <div class="header" style="background-color: rgb(219 219 219)">
          <img alt="Cat Deep" src="./assets/logo.png" class="logo">
          <div class="subheader">
            <h5 style="font-family: 'Passion One', cursive">Random cat pics and Neck Deep lyrics<br>for Mari's pleasure</h5>
          </div>
        </div>
<!--        <div class="divider"></div>-->
        <CatDeep />
        <footer class="footer">&copy; Made with love for Mari<br>Thanks to <a href="https://cataas.com/">CATAAS</a></footer>

      </div>
    </div>
  </div>
</template>

<script>
import CatDeep from './components/CatDeep.vue'

export default {
  name: 'App',
  components: {
    CatDeep
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Passion+One&display=swap');
@import url('./assets/spectre.min.css');
@import url('./assets/spectre-exp.min.css');
@import url('./assets/spectre-icons.min.css');

body {
  background: url('./assets/background.jpg') no-repeat center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.logo {
  max-width: 90%;
}

.subheader {
  padding: 5px;
}
.footer {
  padding: 10px;
  background-color: #2c3e50;
  /*display: flex;*/
  color: white;
  /*align-items: center;*/
  /*justify-content: center;*/
}
</style>
