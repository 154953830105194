import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";

const app = createApp(App)
app.use(VueAxios, axios)
app.use(VueGtag, {
  config: { id: "G-NEXFNYNF0M" }
})
app.provide('axios', app.config.globalProperties.axios)
app.mount('#app')
