<template>

    <div class="content" style="padding-top: 10px">

      <div v-if="message"><strong>{{ message }}</strong></div>

      <div class="controls">
        <div class="form-group">
          <label class="form-radio form-inline">
            <input type="radio" id="picture" value="picture" v-model="pictureType"><i class="form-icon"></i> Picture
          </label>
          <label class="form-radio form-inline">
            <input type="radio" id="random" value="random" v-model="pictureType"><i class="form-icon"></i> Random
          </label>
          <label class="form-radio form-inline">
            <input type="radio" id="gif" value="gif" v-model="pictureType"><i class="form-icon"></i> GIF
          </label>
        </div>

        <div class="btn-group refresh-group" ref="refresh">
          <button v-on:click="getImage" class="btn"><i class="icon icon-refresh"></i> pic</button>
          <button v-on:click="getImageLyric" class="btn btn-primary"><i class="icon icon-refresh"></i> both</button>
          <button v-on:click="getLyric" class="btn"><i class="icon icon-refresh"></i> lyric</button>
        </div>
      </div>

      <div id="img-container" ref="download_el">
        <vue-load-image v-if="currentImgUrl">
          <template v-slot:image>
            <img :src="currentImgUrl" class="img" @load="imgLoaded" ref="img">
            <pre class="bottom-left" v-if="showLyric">{{ lyric.lyric }}</pre>
          </template>
          <template v-slot:preloader>
            <div class="loading loading-lg" style="padding: 60px"></div>
          </template>
          <template v-slot:error>Image load fails</template>
        </vue-load-image>
      </div>

      <div class="lower-buttons" v-if="currentImgUrl">
        <button v-on:click="download" class="btn btn-primary btn-margin">Download</button>
        <a v-on:click="showQuiz" class="btn btn-primary" href="#quiz-modal">Know the song?</a>
      </div>
    </div>

    <div class="modal modal-sm" id="quiz-modal">
      <a href="#close" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container">
            <div class="modal-header">
          <a href="#close" class="btn btn-clear float-right" aria-label="Close"></a>
          <div class="modal-title h5">From which song are the lyrics?</div>
        </div>
        <div class="modal-body">
          <div class="content">
            <div class="form-group">
              <label v-for="option in options" :key="option" class="form-radio">
                <input type="radio" :name="option" :value="option" v-model="quizAnswer">
                <i class="form-icon"></i> {{ option }}
              </label>
            </div>
            <div v-if="lyric && quizAnswer && quizAnswer === lyric.song">
              <div style="color: darkgreen">Correct! Ben would be proud of you :)</div>
              <img src="./../assets/happy-ben.gif" style="max-width: 100%">
            </div>
            <div v-if="lyric && quizAnswer && quizAnswer !== lyric.song">
              <div style="color: darkred">Wrong! Try again, don't disappoint Ben!</div>
              <img src="./../assets/sad-ben.gif" style="max-width: 100%">
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import json from '../../lyrics_parts.json'
import songs from '../../songs.json'
import VueLoadImage from 'vue-load-image'
import * as htmlToImage from 'html-to-image';

export default {
  name: 'CatDeep',
  components: {
    'vue-load-image': VueLoadImage
  },
  data(){
      return{
          lyricList: json,
          lyric: '',
          currentImgUrl: null,
          imgUrl: null,
          pictureType: 'random',
          showLyric: false,
          songs: songs,
          options: [],
          quizAnswer: null,
          message: null
      }
  },
  methods: {
    imgLoaded() {
      if (this.$refs.img && this.$refs.img.clientHeight > this.$refs.refresh.offsetHeight) {
        this.showLyric = true
      }
    },

    getLyric() {
      this.lyric = this.lyricList[Math.floor(Math.random() * this.lyricList.length)]
      this.quizAnswer = null
    },

    getImage() {
      this.showLyric = false
      if (this.pictureType === 'picture') {
        this.imgUrl = 'https://cataas.com/cat?json=true'
      } else if (this.pictureType === 'gif') {
        this.imgUrl = 'https://cataas.com/cat/gif?json=true'
      } else {
        if (Math.random() < 0.5){
          this.imgUrl = 'https://cataas.com/cat/gif?json=true'
        } else {
          this.imgUrl = 'https://cataas.com/cat?json=true'
        }
      }
      this.axios.get(this.imgUrl).then((response) => {
        this.currentImgUrl = `https://cataas.com/cat/${response.data._id}`
      })
    },

    getImageLyric() {
      this.getImage()
      this.getLyric()
    },

    download() {
      htmlToImage.toJpeg(document.getElementById('img-container'), { quality: 1 })
      .then(function (dataUrl) {
        let link = document.createElement('a');
        link.download = 'cat-deep.jpeg';
        link.href = dataUrl;
        link.click();
      });
    },

    showQuiz() {
      const shuffledSongs = this.songs.filter(x => x !== this.lyric.song).sort(() => 0.5 - Math.random());
      let options = shuffledSongs.slice(0, 3);
      options.push(this.lyric.song)
      this.options = options.sort(() => 0.5 - Math.random());
    },

    isToday(someDate) {
      const today = new Date()
      return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear()
    }
  },

  mounted() {
    this.getImageLyric()
    let birthDay = new Date(`${new Date().getFullYear()}-12-27`)
    let skNameDay = new Date(`${new Date().getFullYear()}-09-07`)
    let czNameDay = new Date(`${new Date().getFullYear()}-09-08`)
    if (this.isToday(birthDay)) {
      this.message = 'Happy birthday Mari!!!'
    } else if (this.isToday(skNameDay)) {
      this.message = 'Today is your namesday in Slovakia Mari, parabéns!'
    } else if (this.isToday(czNameDay)) {
      this.message = 'Today is your namesday in Czechia Mari, parabéns!'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#img-container {
  position: relative;
  text-align: center;
  color: white;
  margin: 10px;
}

.img {
  width: 100%;
  height: 100%;
  max-width: 500px;
}

.bottom-left {
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  min-width: 500px;
  font-family: 'Passion One', cursive;
  font-size: 22px;
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.btn-margin {
  margin-right: 5px;
}

.lower-buttons {
  margin-bottom: 15px;
}

/* Large desktop */
@media (min-width: 1200px) {
  .bottom-left {
    min-width: 500px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .bottom-left {
    min-width: 500px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .bottom-left {
    min-width: 500px;
  }
}

/* Landscape phones and down */
@media (max-width: 480px) {
  .bottom-left {
    min-width: 340px;
  }
}
</style>
